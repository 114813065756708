<template>
  <div>
    <v-row class="home">
      <v-col cols="12" md="6" class="mt-5">
        <strong class="google-add-text font-family-raleway-bold">
          {{ $t("home_page.title") }}
        </strong>
        <p class="description mt-4">
          {{ $t("home_page.description") }}
        </p>
      </v-col>
      <v-col cols="12" md="6">
        <div class="image-container">
          <v-img
            :src="macbook"
            class="image"
            :loading="true"
            alt="MacBook Screen"
            width="650"
            height="405"
          />
          <iframe
            title="Introduction to PivotAd website"
            src="https://www.youtube.com/embed/sl1-AlaAB-E"
            class="responsive-iframe"
            frameborder="0"
            allowfullscreen
            loading="lazy"
            preload="none"
          ></iframe>
        </div>
      </v-col>
      <v-col class="text-center" cols="12">
        <h5 class="text my-5">
          {{ $t("home_page.get_started_text") }}
        </h5>
        <v-btn
          class="start-trial-btn mt-5"
          style="font-family: 'Raleway light', sans-serif"
          color="#fff"
          outlined
          @click="registerModal = true"
        >
          {{ $t("home_page.start_trial_button") }}
        </v-btn>
      </v-col>
      <Login
        :dialog="loginModal"
        @closeModal="loginModal = false"
        @openSignUp="registerModal = true"
      />
      <Registration
        :dialog="registerModal"
        @closeModal="registerModal = false"
        @openSignIn="loginModal = true"
      />
    </v-row>
    <!--    <Footer color="#fff"/>-->
  </div>
</template>
<script>
import macbook from "@/assets/img/Device - Macbook Air.svg";

export default {
  name: "Home",
  components: {
    Registration: () => import("@/components/MainLanding/Modals/Registration"),
    Login: () => import("@/components/MainLanding/Modals/Login"),
  },
  data() {
    return {
      macbook,
      registerModal: false,
      loginModal: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.home {
  padding: 80px 0;
  @media screen and (max-width: 600px) {
    padding: 10px 0;
  }

  .google-add-text {
    font-weight: bold;
    font-size: 45px;
    color: #ffffff;
    font-family: "Raleway bold", sans-serif !important;
    @media screen and (max-width: 600px) {
      font-size: 36px;
    }
  }

  .description {
    color: #ffffff;
    font-weight: 200 !important;
    font-size: 20px;
    font-family: "Raleway medium", sans-serif !important;
  }

  .text {
    font-size: 36px;
    color: #ffffff;
    font-weight: 500;
    font-family: "Raleway light", sans-serif !important;
  }
}

.start-trial-btn {
  border-radius: 16px;
  height: 65px !important;
  background-color: #0057ff;
  border: none !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  box-shadow: 0px 4px 24px rgba(34, 41, 51, 0.1);
  padding: 0 20px !important;
}

.image {
  width: 100% !important;
}

.image-container {
  width: auto;
  height: auto;
  position: relative !important;

  .responsive-iframe {
    position: absolute !important;
    left: 12.5%;
    top: 4%;
    width: 75%;
    height: 75%;
  }
}
</style>
